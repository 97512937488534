import React from "react";
import Layout from "../components/common/Layout";
import ProjectLogos from "../components/home/ProjectLogos";

const About = () => {
  return (
    <Layout>
      <section className="residence-banner relative">
        <div className="d-flex align-items-center abs-full tint">
          <div className="container">
            <div className="d-flex justify-content-center align-items-center">
              <div className="text-center">
                <h2 className="banner-title">About</h2>
                {/* <p class="banner-tagline">Improving Lifestyles - Enhancing Lives</p> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="residence-section">
        <div className="container">
          <div>
            <h5 className="residence-section-title">About</h5>
            <p>
              A robust three decade plus presence in the Tamil Nadu
              Infrastructure and Real Estate Vertical makes Baashyaam
              Constructions Pvt. Ltd. stand tall, among its compatriots. It has
              ably showcased its abilities with multiple projects across
              categories, ranging from Affordable Housing, Independent Villas,
              Premium Living Spaces, Luxury Residences, Township and Commercial
              Buildings. This has helped enrich its vision of Improving
              Lifestyles - Enhancing Lives, for the past thirty years.
            </p>
            <p>
              The brand is credited for being one of the very few developers
              with end-to-end capabilities, right from project inception to
              finish. It has an enviable reputation for being a transparent
              business house, with sound emphasis on quality and commitment
              towards honouring promises.
            </p>
            <p className="mb-0">
              When it comes to embracing new technology and best practices,
              Baashyaam is an early adopter. It has an incessant passion for
              innovation and has a strong obsession for on-time delivery of
              projects. When you add to this, stringent quality standards, high
              degree of dependability and unflinching attention to detail, you
              have a reliable brand of choice for customers.
            </p>
          </div>
        </div>
      </section>
      <ProjectLogos />
    </Layout>
  );
};

export default About;
